import * as Offcanvas from 'bootstrap/js/dist/offcanvas';

const offcanvasCollapse = document.getElementById('site-nav-offcanvas');
const offcanvasCollapseMq = '(min-width: 992px)';

// close offcanvas if breakpoint condition is met
if (offcanvasCollapse && offcanvasCollapseMq) {
  window.matchMedia(offcanvasCollapseMq).addEventListener('change', (e) => {
    if (e.matches) {
      const offcanvasInstance = Offcanvas.getInstance(offcanvasCollapse);
      if (offcanvasInstance) {
        offcanvasInstance.hide();
      }
    }
  });
}
