import axios from 'axios';
import { rootWithLanguage } from '@/js/utils/paths';

/**
 * get regex
 * @param controller
 * @returns {Promise}
 */
export function addToFavorites({ artworkId }) {
  return axios({
    method: 'post',
    url: `${rootWithLanguage}artworks/addtofavorites`,
    data: {
      ArtworkID: artworkId,
    },
  });
}

export function removeFromFavorites({ artworkId }) {
  return axios({
    method: 'post',
    url: `${rootWithLanguage}artworks/removefromfavorites`,
    data: {
      ArtworkID: artworkId,
    },
  });
}
