import Validation from '@/js/components/forms/validation';

class ComingSoon {
  constructor() {
    this.dom = {
      subscriberForm: document.getElementById('subscriber-form'),
    };

    this.components = {
      subscriberFormValidation: null,
    };

    this.mount();
  }

  mount() {
    if (this.dom.subscriberForm) {
      this.components.subscriberFormValidation = new Validation(this.dom.subscriberForm);
    }
  }
}

if (document.getElementById('page-coming-soon')) {
  // eslint-disable-next-line no-new
  new ComingSoon();
}
