/* eslint-disable import/no-import-module-exports, no-unused-vars */
import './styles/index.scss';

// vendor
import {
  Alert, Dropdown, Offcanvas, Modal,
} from 'bootstrap';

import 'masonry-layout';
import 'imagesloaded';
import './js/components/masonry/imagesloaded';
import './js/components/show_more/show_more';

// views
import './js/views';
import share from '@/js/components/share/share';
import viewRoom from '@/js/components/view_room/view_room';

// load all icons
(function loadIcons(r) {
  r.keys().forEach(r);
}(require.context('./img/icons', true, /\.svg$/)));

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) module.hot.accept();

// share buttons
document.querySelectorAll('.js-share').forEach((shareEl) => shareEl.addEventListener('click', () => {
  share({
    title: shareEl.dataset.title,
  });
}));

// viewroom
document.querySelectorAll('.js-room-toggle').forEach((roomEl) => roomEl.addEventListener('click', () => {
  viewRoom({
    width: roomEl.dataset.width,
  });
}));
