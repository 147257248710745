/* global google */

import * as Styles from '@/json/map_styles.json';
import Marker from './marker';

export default class GoogleMaps {
  constructor(mapEl, options) {
    window.onMapLoaded = this.onMapLoaded.bind(this);
    const defaultOptions = {
      center: { lat: 50.91263082619794, lng: 3.647702889873862 }, // Web@vantage coordinates
      zoom: 13,
      styles: Styles,
    };

    this.dom = {
      mapEl,
    };

    this.data = {
      map: null,
      bounds: null,
      key: '',
      callback: 'onMapLoaded',
      options: { ...defaultOptions, ...options },
    };

    this.components = {
      markers: [],
      infoWindows: [],
    };

    this.customEvents = {
      loaded: null,
    };

    if (!window.google) {
      // load map
      this.loadMap();
    } else {
      // maps is already loaded
      this.onMapLoaded();
    }
  }

  // load google maps scripts
  loadMap() {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?&callback=${this.data.callback}&key=${this.data.key}`;
    document.body.appendChild(script);
  }

  // google maps is loaded
  onMapLoaded() {
    // create instance
    this.data.map = new google.maps.Map(this.dom.mapEl, this.data.options);

    // add bounds
    this.data.bounds = new google.maps.LatLngBounds();

    // create event
    this.customEvents.loaded = new CustomEvent('loaded', { detail: this.data.map });

    // dispatch event
    this.dom.mapEl.dispatchEvent(this.customEvents.loaded);
  }

  /**
   * add marker to map
   * @param markerData {Object}
   * @param infoWindowContent {String}
   */
  addMarker(markerData, infoWindowContent = null) {
    // merge map with options
    const options = { ...markerData, ...{ map: this.data.map } };

    // create maker instance
    const marker = new Marker(options, infoWindowContent);

    // keep marker instance ref
    this.components.markers.push(marker);

    // extend bounds
    this.data.bounds.extend(markerData.position);

    /**
     * if we have more multiple markers, fit map within markers bounds
     * else, use the map its center pos
     */
    if (this.components.markers.length > 1) {
      this.data.map.fitBounds(this.data.bounds);
    }
  }
}
