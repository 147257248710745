import Validation from '@/js/components/forms/validation';

class Preview {
  constructor() {
    this.dom = {
      eventForm: document.getElementById('event-form'),
    };

    this.components = {
      eventFormValidation: null,
    };

    this.mount();
  }

  mount() {
    if (this.dom.eventForm) {
      this.components.eventFormValidation = new Validation(this.dom.eventForm);
    }
  }
}

if (document.getElementById('page-preview')) {
  // eslint-disable-next-line no-new
  new Preview();
}
