// components
import Validation from '@/js/components/forms/validation';

class Login {
  constructor() {
    this.dom = {
      loginForm: document.getElementById('login-form'),
      passwordForgotForm: document.getElementById('password-forgotten-form'),
      passwordResetForm: document.getElementById('password-reset-form'),
    };

    this.components = {
      loginFormValidation: null,
      passwordForgotFormValidation: null,
      passwordResetFormValidation: null,
    };

    this.mount();
  }

  mount() {
    if (this.dom.loginForm) {
      this.components.loginFormValidation = new Validation(this.dom.loginForm);
    }

    if (this.dom.passwordForgotForm) {
      this.components.passwordForgotFormValidation = new Validation(this.dom.passwordForgotForm);
    }

    if (this.dom.passwordResetForm) {
      this.components.passwordResetFormValidation = new Validation(this.dom.passwordResetForm);
    }
  }
}

if (document.getElementById('page-login') || document.getElementById('page-password-forgotten') || document.getElementById('page-password-reset')) {
  // eslint-disable-next-line no-new
  new Login();
}
