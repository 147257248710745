// eslint-disable-next-line func-names
const onTogglePasswordClick = function (e) {
  const input = e.currentTarget.parentNode.querySelector('input');
  if (input.type === 'password') {
    input.type = 'text';
  } else {
    input.type = 'password';
  }
};

document.querySelectorAll('.toggle-password').forEach((el) => {
  el.addEventListener('click', onTogglePasswordClick);
});

// Check if password fields are the same
const passwords = document.querySelectorAll('.password');
const confirmPasswords = document.querySelectorAll('.password-confirm');

// eslint-disable-next-line func-names
const checkPassword = function () {
  const checkmark = document.querySelectorAll('.pass-success');
  const cross = document.querySelectorAll('.pass-danger');
  if (passwords[0].value === confirmPasswords[0].value) {
    checkmark[0].classList.remove('d-none');
    cross[0].classList.add('d-none');
  } else if (confirmPasswords[0].value === '') {
    checkmark[0].classList.add('d-none');
    cross[0].classList.add('d-none');
  } else {
    cross[0].classList.remove('d-none');
    checkmark[0].classList.add('d-none');
  }
};

confirmPasswords.forEach((el) => {
  el.addEventListener('keyup', checkPassword);
});
