class Home {
  constructor() {
    this.dom = {
    };

    this.components = {
    };
  }
}

if (document.getElementById('page-home')) {
  // eslint-disable-next-line no-new
  new Home();
}
