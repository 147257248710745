import * as ImagesLoaded from 'imagesloaded';
import * as MasonryLayout from 'masonry-layout';

// Check if the grid element exists before proceeding
const grid = document.querySelector('.art-gallery');
if (grid !== null) {
  const msnry = new MasonryLayout(grid, {
    itemSelector: '.artwork-block',
    percentPosition: true,
  });

  ImagesLoaded(grid).on('progress', () => {
    console.log('All images are loaded');

    // Layout Masonry after each image loads
    msnry.layout();
  });
}
